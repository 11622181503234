import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface TokenState {
  value: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: TokenState = {
  value: '',
  status: 'idle',
};

export const TokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    registerKeycloakToken: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { registerKeycloakToken } = TokenSlice.actions;

export default TokenSlice.reducer;
