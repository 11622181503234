import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryOptions from './baseQueryOptions';
import { Reminder, Statistics, StatisticsOperational } from '../interfaces';

export const metricsApi = createApi({
  reducerPath: 'metricsApi',
  baseQuery: baseQueryOptions,
  keepUnusedDataFor: 60,
  tagTypes: ['Metrics', 'Reminders', 'Statistics'],
  endpoints: (builder) => ({
    getLettersInTimeStreak: builder.query<
      { value: number },
      {
        masterRef: string;
        tenantId: string;
      }
    >({
      query: (queryArgs: { masterRef: string; tenantId: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/collection/statistics/lettersInTimeStreak`,
        method: 'GET',
      }),
      providesTags: ['Metrics'],
    }),
    getReminders: builder.query<
      { items: Reminder[]; totalItems: number },
      {
        masterRef: string;
        tenantId: string;
        qp: string;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        tenantId: string;
        qp: string;
      }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/reminders?${queryArgs.qp}`,
        method: 'GET',
      }),
      providesTags: ['Reminders'],
    }),
    getStatistics: builder.query<
      { totalItems: number; items: Statistics[] },
      {
        masterRef: string;
        tenantId: string;
        qp: string;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        tenantId: string;
        qp: string;
      }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/statistics?${queryArgs.qp}`,
        method: 'GET',
      }),
      providesTags: ['Statistics'],
    }),
    getStatisticsOperational: builder.query<
      StatisticsOperational,
      {
        masterRef: string;
        tenantId: string;
      }
    >({
      query: (queryArgs: { masterRef: string; tenantId: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/statistics/operational`,
        method: 'GET',
      }),
      providesTags: ['Statistics'],
    }),
  }),
});

export const {
  useGetLettersInTimeStreakQuery,
  useGetRemindersQuery,
  useGetStatisticsQuery,
  useGetStatisticsOperationalQuery,
} = metricsApi;
