import { AxiosResponse } from 'axios';
import { KeycloakConfig } from 'keycloak-js';
import { Movement, RouterParams } from '../interfaces';
import axiosInstance from './axios-instance';

const getOidcConfiguration = (): Promise<AxiosResponse<KeycloakConfig>> =>
  axiosInstance.get('/public/oidc-configuration');

const getLastSuccessMouvement = (
  props: Pick<RouterParams, 'masterRef'>
): Promise<AxiosResponse<Movement>> =>
  axiosInstance.get(
    `/api/masters/${props.masterRef}/data-integration/movements/last-success`,
    { withCredentials: true }
  );

const upsertWithCsv = (props: {
  uploadType: string;
  masterRef: string;
  formData: FormData;
}): Promise<AxiosResponse<any>> =>
  axiosInstance.post(
    `/api/masters/${props.masterRef}/data-integration/${props.uploadType}`,
    props.formData
  );

const uploadLogoCompany = (props: {
  masterRef: string;
  formData: FormData;
}): Promise<AxiosResponse<any>> =>
  axiosInstance.put(
    `/api/masters/${props.masterRef}/companyLogo`,
    props.formData
  );

const Api = {
  getOidcConfiguration,
  getLastSuccessMouvement,
  upsertWithCsv,
  uploadLogoCompany,
};

export default Api;
