import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryOptions from './baseQueryOptions';
import {
  CompanyInformation,
  GenericList,
  KycVerifyResponse,
  DebtCollectionFee,
  Tenant,
  TenantMasterInfo,
  UserCreate,
} from '../interfaces';
import { TenantHistory } from '../interfaces/TenantHistory';

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: baseQueryOptions,
  keepUnusedDataFor: 60,
  tagTypes: [
    'Invoice',
    'Invoices',
    'Master',
    'Rate',
    'Tenant',
    'Tenants',
    'TenantInfo',
    'TenantCompanyInfo',
    'TenantFile',
    'Users',
    'UserFeature',
  ],
  endpoints: (builder) => ({
    createMaster: builder.mutation<
      TenantMasterInfo,
      {
        masterRef: string;
        tenantId: string;
        data: TenantMasterInfo;
      }
    >({
      query: (queryArgs: {
        masterRef: string;
        tenantId: string;
        data: TenantMasterInfo;
      }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}`,
        method: 'PUT',
        body: queryArgs.data,
      }),
      invalidatesTags: ['Master', 'TenantInfo'],
    }),
    createTenant: builder.mutation<any, { user: UserCreate }>({
      query: (queryArgs: { user: UserCreate }) => ({
        url: `/api/admin/users`,
        method: 'POST',
        body: {
          login: queryArgs.user.login,
          firstname: queryArgs.user.firstname,
          lastname: queryArgs.user.lastname,
          password: queryArgs.user.password,
          phone: queryArgs.user.phone,
          demo: queryArgs.user.demo,
          roles: queryArgs.user.roles,
        },
      }),
      invalidatesTags: ['Tenant'],
    }),
    addUserToTenant: builder.mutation<
      any,
      { user: UserCreate; tenantId: string }
    >({
      query: (queryArgs: { user: UserCreate; tenantId: string }) => ({
        url: `/api/admin/users`,
        method: 'POST',
        body: {
          login: queryArgs.user.login,
          firstname: queryArgs.user.firstname,
          lastname: queryArgs.user.lastname,
          password: queryArgs.user.password,
          phone: queryArgs.user.phone,
          demo: queryArgs.user.demo,
          roles: queryArgs.user.roles,
        },
        headers: {
          'X-TenantId': queryArgs.tenantId,
        },
      }),
      invalidatesTags: ['Tenant', 'Users'],
    }),
    deleteTenant: builder.mutation<number, { tenantId: string }>({
      query: (queryArgs: { tenantId: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/delete`,
        method: 'DELETE',
        responseHandler: 'text',
      }),
      invalidatesTags: ['Tenants'],
    }),
    getTenantsById: builder.query<Tenant, { qp: string }>({
      query: (queryArgs: { qp: string }) =>
        `/api/admin/tenants?${queryArgs.qp}`,
      transformResponse: ({
        items,
        totalItems,
      }: {
        items: any[];
        totalItems: number;
      }) => (totalItems === 0 ? null : items[0]),
      providesTags: ['Tenant'],
    }),
    getTenants: builder.query<
      { totalItems: number; items: any[] },
      { qp: string }
    >({
      query: (queryArgs: { qp: string }) =>
        `/api/admin/tenants?${queryArgs.qp}`,
      providesTags: ['Tenants'],
    }),
    getTenantByIdAndMaster: builder.query<
      TenantMasterInfo,
      {
        masterRef: string | undefined;
        tenantId: string | undefined;
        qp: string;
      }
    >({
      query: (queryArgs: {
        masterRef: string | undefined;
        tenantId: string | undefined;
        qp: string;
      }) =>
        `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}?${queryArgs.qp}`,
      providesTags: ['TenantInfo'],
    }),
    getLastSuccessMovements: builder.query<
      {
        createdDate: string;
      },
      {
        masterRef: string | undefined;
        tenantId: string | undefined;
      }
    >({
      query: (queryArgs: {
        masterRef: string | undefined;
        tenantId: string | undefined;
      }) =>
        `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/data-integration/movements/last-success`,
      providesTags: ['TenantInfo'],
    }),
    getTenantByIdAndUserlogin: builder.query<
      Tenant,
      { userLogin: string; tenantId: string | undefined; qp: string }
    >({
      query: (queryArgs: { userLogin: string; tenantId: string; qp: string }) =>
        `/api/admin/tenants/${queryArgs.tenantId}/users/${queryArgs.userLogin}?${queryArgs.qp}`,
      providesTags: ['TenantInfo'],
    }),
    getUserFeature: builder.query<
      any,
      { tenantId: string | undefined; userUuid: string }
    >({
      query: (queryArgs: { userUuid: string; tenantId: string }) =>
        `/api/admin/tenants/${queryArgs.tenantId}/users/${queryArgs.userUuid}/features`,
      providesTags: ['UserFeature'],
    }),
    getCompanyInformation: builder.query<
      CompanyInformation,
      { masterRef: string | undefined; isOnboarding: string }
    >({
      query: (queryArgs: { masterRef: string; isOnboarding: string }) =>
        `/api/company-information?legalId=${queryArgs.masterRef}&isOnboarding=${queryArgs.isOnboarding}`,
      providesTags: ['TenantCompanyInfo'],
    }),
    getMasterAvailability: builder.query<
      {
        available: boolean;
        email: string;
      },
      { masterRef: string }
    >({
      query: ({ masterRef }: { masterRef: string }) =>
        `/api/tenants/available/master/${masterRef}`,
    }),
    verifyTenant: builder.mutation<
      any,
      {
        tenantId: string;
        contractSigned: boolean;
        verified: boolean;
        comment: string;
      }
    >({
      query: (queryArgs: {
        tenantId: string;
        contractSigned: boolean;
        verified: boolean;
        comment: string;
      }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}`,
        method: 'PATCH',
        body: {
          contractSigned: queryArgs.contractSigned,
          verified: queryArgs.verified,
          comment: queryArgs.comment,
        },
      }),
      invalidatesTags: ['Tenants'],
    }),
    getInvoices: builder.query<any, { tenantId: string; qp: string }>({
      query: (queryArgs: { tenantId: string; qp: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/invoices?${queryArgs.qp}`,
      }),
      providesTags: ['Invoice', 'Invoices'],
    }),
    getInvoice: builder.query<any, { invoiceNumber: string; tenantId: string }>(
      {
        query: (queryArgs: { invoiceNumber: string; tenantId: string }) => ({
          url: `/api/admin/tenants/${queryArgs.tenantId}/invoices/${queryArgs.invoiceNumber}`,
        }),
        providesTags: ['Invoice', 'Invoices'],
      }
    ),
    getHistory: builder.query<
      TenantHistory[],
      { size: string; tenantId: string }
    >({
      query: (queryArgs: { size: string; tenantId: string }) => ({
        url: `/api/admin/tenants/history?size=${queryArgs.size}&tenantId=${queryArgs.tenantId}`,
        method: 'GET',
      }),
      providesTags: ['Tenants'],
    }),
    getLastKycProcess: builder.query<
      KycVerifyResponse,
      { tenantId: string; masterRef: string }
    >({
      query: ({
        tenantId,
        masterRef,
      }: {
        tenantId: string;
        masterRef: string;
      }) =>
        `/api/admin/tenants/${tenantId}/masters/${masterRef}/kyc/process/last`,
      providesTags: ['Tenant'],
    }),
    getRateHistory: builder.query<
      GenericList<DebtCollectionFee>,
      { qp?: string }
    >({
      query: ({ qp }: { qp?: string }) =>
        `/api/admin/debt-collection-fees?${qp}`,
      providesTags: ['Rate'],
    }),
    updateRate: builder.mutation<any, { body: any }>({
      query: (queryArgs: { body: string }) => ({
        url: `/api/admin/debt-collection-fees`,
        method: 'POST',
        body: queryArgs.body,
      }),
      invalidatesTags: ['Rate'],
    }),
    deleteRate: builder.mutation<void, { masterRef: string }>({
      query: (queryArgs: { masterRef: string }) => ({
        url: `/api/admin/debt-collection-fees/${queryArgs.masterRef}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Rate'],
    }),
  }),
});

export const {
  useCreateMasterMutation,
  useCreateTenantMutation,
  useAddUserToTenantMutation,
  useDeleteTenantMutation,
  useGetTenantByIdAndMasterQuery,
  useGetTenantsByIdQuery,
  useGetTenantsQuery,
  useGetUserFeatureQuery,
  useGetCompanyInformationQuery,
  useGetMasterAvailabilityQuery,
  useGetLastSuccessMovementsQuery,
  useVerifyTenantMutation,
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useGetHistoryQuery,
  useGetLastKycProcessQuery,
  useGetRateHistoryQuery,
  useUpdateRateMutation,
  useDeleteRateMutation,
} = tenantApi;
