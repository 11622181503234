import { createApi } from '@reduxjs/toolkit/query/react';
import { Template } from '../interfaces';
import baseQueryOptions from './baseQueryOptions';

// Define a service using a base URL and expected endpoints
export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: baseQueryOptions,
  tagTypes: ['Template', 'Templates'],
  endpoints: (builder) => ({
    getTemplateList: builder.query<Template[], { language: string }>({
      query: (queryArgs: { language: string }) =>
        `/api/templates/list?language=${queryArgs.language}`,
      providesTags: ['Templates'],
    }),
    getTemplate: builder.query<Template, { code: string; language: string }>({
      query: (queryArgs: { code: string; language: string }) =>
        `/api/templates?code=${queryArgs.code}&language=${queryArgs.language}`,
      providesTags: ['Template'],
    }),
    createTemplate: builder.mutation<
      void,
      { code: string; language: string; type: string; content: string }
    >({
      query: (queryArgs: {
        code: string;
        language: string;
        type: string;
        content: string;
      }) => ({
        url: `/api/templates?code=${queryArgs.code}&language=${queryArgs.language}&type=${queryArgs.type}`,
        body: queryArgs.content,
        method: 'POST',
      }),
      invalidatesTags: ['Template', 'Templates'],
    }),
    generateHtml: builder.mutation<
      string,
      {
        qp: string;
        content: string;
      }
    >({
      query: (queryArgs: { qp: string; content: string }) => ({
        url: `/api/admin/dev-services/modeles/generate-html?${queryArgs.qp}`,
        method: 'POST',
        body: queryArgs.content,
        responseHandler: 'text',
      }),
    }),
  }),
});

export const {
  useGetTemplateListQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useGenerateHtmlMutation,
} = templateApi;
