import React, { lazy, Suspense } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useGetMeQuery } from '../../services/meApi';
const MenuTopDesktopVersion = lazy(() => import('./menu-top-desktop-version'));

const NavBarTop = () => {
  const { data: user } = useGetMeQuery();

  return (
    <Navbar bg="white" variant="light" className="menu-main shadow-nav">
      <Container fluid={true}>
        <Suspense fallback={'...'}>
          <MenuTopDesktopVersion user={user} />
        </Suspense>
      </Container>
    </Navbar>
  );
};

export default NavBarTop;
