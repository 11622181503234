import { KeycloakInstance } from 'keycloak-js';
import React, { useEffect, useState } from 'react';
import Loading from '../shared/components/loading';

const LoadingsRouter = ({
  initialized,
  keycloak,
  masterFetched,
}: {
  initialized: boolean;
  keycloak: KeycloakInstance;
  masterFetched: boolean;
}) => {
  const [loadingMessage, setLoadingMessage] = useState<string>(
    'loading.not-initialized'
  );

  useEffect(() => {
    if (!initialized) return;
    if (!masterFetched && keycloak.authenticated) {
      setLoadingMessage('loading.user-fetching');
    }
  }, [initialized, masterFetched, keycloak.authenticated]);

  return <Loading message={loadingMessage} />;
};

LoadingsRouter.defaultProps = { initialized: false, masterFetched: false };
export default LoadingsRouter;
