import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const CardLinks = (props: {
  label: string;
  active: boolean;
  onClick: Function;
  icon?: IconDefinition;
}) => {
  const { active, label, onClick, icon } = props;
  const classname = active ? 'card-link-active' : 'card-link';
  return (
    <div
      className={classname}
      data-testid="card-link"
      onClick={() => onClick()}
    >
      {label}
      {icon && <FontAwesomeIcon icon={icon} />}
    </div>
  );
};
export default CardLinks;
