import React, { cloneElement, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GenericButtonProps } from '../interfaces';
import I18nWrapper from './i18n-wrapper';

const GenericButton = (props: GenericButtonProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsActive(props?.active || false);
  }, [props?.active]);

  return (
    <Button
      disabled={props?.disabled}
      size="sm"
      active={isActive}
      type={props.type}
      variant={props.variant}
      className={props.className}
      onClick={() => props.action()}
      aria-label={t(props.label)}
      title={props?.title}
      data-testid={props?.testid}
    >
      {props.prependElement && <>{cloneElement(props.prependElement)}</>}
      <I18nWrapper translateKey={props.label} />
      {props.appendElement && <>{cloneElement(props.appendElement)}</>}
    </Button>
  );
};

GenericButton.defaultProps = {
  type: 'button',
  variant: 'primary',
  action: () => {
    // sonar
  },
};

export default GenericButton;
