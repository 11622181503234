import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryOptions from './baseQueryOptions';
import { ReminderWorkflow, WorkflowDefinition } from '../interfaces';

export const reminderApi = createApi({
  reducerPath: 'reminderApi',
  baseQuery: baseQueryOptions,
  keepUnusedDataFor: 60,
  tagTypes: ['Workflows'],
  endpoints: (builder) => ({
    getWorkflowList: builder.query<
      ReminderWorkflow[],
      {
        tenantId: string;
        masterRef: string;
      }
    >({
      query: (queryArgs: { tenantId: string; masterRef: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/reminder-workflows`,
        method: 'GET',
      }),
      providesTags: ['Workflows'],
    }),
    changeWorkflow: builder.mutation<
      void,
      {
        id: number;
        tenantId: string;
        masterRef: string;
        content: { definition: WorkflowDefinition };
      }
    >({
      query: (queryArgs: {
        id: number;
        tenantId: string;
        masterRef: string;
        content: { definition: WorkflowDefinition };
      }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/masters/${queryArgs.masterRef}/reminder-workflows/${queryArgs.id}`,
        method: 'PUT',
        body: queryArgs.content,
      }),
      invalidatesTags: ['Workflows'],
    }),
  }),
});

export const { useGetWorkflowListQuery, useChangeWorkflowMutation } =
  reminderApi;
