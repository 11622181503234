import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nWrapperProps } from '../interfaces';

const I18nWrapper = (props: I18nWrapperProps) => {
  const { t } = useTranslation();
  const [prefix, setPrefix] = useState<string>('');

  useEffect(() => {
    if (!props.prefix) return;
    setPrefix(`${props.prefix}.`);
  }, [props.prefix]);
  const generateI18nWith18n = useCallback(
    (args: I18nWrapperProps) => ({
      __html: t(prefix + args.translateKey, args.options),
    }),
    [prefix, t]
  );

  if (props.useHtml) {
    return <span dangerouslySetInnerHTML={generateI18nWith18n(props)} />;
  }

  return <>{t(prefix + props.translateKey, props.options)}</>;
};

I18nWrapper.defaultProps = {
  useHtml: false,
};
export default I18nWrapper;
