import { createApi } from '@reduxjs/toolkit/query/react';
import { TenantUserInfo, User } from '../interfaces';
import baseQueryOptions from './baseQueryOptions';

// Define a service using a base URL and expected endpoints
export const meApi = createApi({
  reducerPath: 'meApi',
  baseQuery: baseQueryOptions,
  tagTypes: ['User', 'Users'],
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => `/api/me`,
      providesTags: ['User'],
    }),
    getUsers: builder.query<
      TenantUserInfo[],
      { tenantId: string | undefined; userIds: string[] }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const response: any[] = await Promise.all(
          _arg.userIds.map((userId) =>
            fetchWithBQ(`/api/admin/tenants/${_arg.tenantId}/users/${userId}`)
          )
        );
        let error = false;
        const merged = [].concat(
          ...response.map((res) => {
            if (res.error) error = true;
            if (res.data) {
              return res.data;
            }
            return {};
          })
        );

        return error ? { data: [] } : { data: merged };
      },
      providesTags: ['Users'],
    }),
    resetCredentials: builder.mutation<
      void,
      { tenantId: string; userLogin: string }
    >({
      query: (queryArgs: { tenantId: string; userLogin: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/users/${queryArgs.userLogin}/reset-credentials`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    updateMainUser: builder.mutation<
      void,
      { tenantId: string; userUUID: string }
    >({
      query: (queryArgs: { tenantId: string; userUUID: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/users/${queryArgs.userUUID}/main`,
        method: 'PUT',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUsersQuery,
  useResetCredentialsMutation,
  useUpdateMainUserMutation,
} = meApi;
