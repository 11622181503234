import {TenantMasterInfo} from '../../interfaces';
import {Col, Row, Tab} from 'react-bootstrap';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const PaymentTab = ({
  isFetching,
  tenant,
}: {
  isFetching: boolean;
  tenant: TenantMasterInfo | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Tab.Content>
      <h2 className="tab-title">{t('master-info.title.payment')}</h2>
      <Row className="info-row">
        <Col sm={3} className="info-title">
          Par chèque à
        </Col>
        <Col className="info-value">
          {isFetching ? (
            <Skeleton width={200} />
          ) : (
            tenant?.howToPay?.chequePayableTo
          )}
        </Col>
      </Row>
      {tenant?.howToPay?.transfer && (
        <>
          <Row className="info-row">
            <Col sm={3} className="info-title">
              Banque
            </Col>
            <Col className="info-value">
              {tenant.howToPay.transfer.bankName}
            </Col>
          </Row>
          <Row className="info-row">
            <Col sm={3} className="info-title">
              IBAN
            </Col>
            <Col className="info-value">{tenant.howToPay.transfer.iban}</Col>
          </Row>
          <Row className="info-row">
            <Col sm={3} className="info-title">
              BIC
            </Col>
            <Col className="info-value">{tenant.howToPay.transfer.bic}</Col>
          </Row>
        </>
      )}
      {tenant?.settings?.accountingCurrency && (
        <Row className="info-row">
          <Col sm={3} className="info-title">
            Devise
          </Col>
          <Col className="info-value">
            {tenant.settings.accountingCurrency.code}
          </Col>
        </Row>
      )}
    </Tab.Content>
  );
};

export default PaymentTab;
