import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ColBreadcrumbItemProps } from '../interfaces';
import I18nWrapper from './i18n-wrapper';

const BreadcrumbItem = (props: { item: ColBreadcrumbItemProps }) => {
  return (
    <Breadcrumb.Item
      linkAs={Link}
      linkProps={{ to: props.item.linkGoTo }}
      active={props.item?.active ? props.item.active : false}
    >
      <I18nWrapper translateKey={props.item.linkLabel} />
    </Breadcrumb.Item>
  );
};

export default BreadcrumbItem;
