import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../state/store';

const baseQueryOptions = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).token;
    const tenantId = (getState() as RootState).tenantId;

    if (token) {
      headers.set('authorization', `Bearer ${token.value}`);
    }

    if (tenantId.value) {
      headers.set('X-TenantId', String(tenantId.value));
    }

    return headers;
  },
});

export default baseQueryOptions;
