import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import translationFR from './locales/fr/translations.json';

const resources = {
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  // passes i18n down to react-i18next
  .init({
    resources,
    compatibilityJSON: 'v3',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => r);

export default i18n;
