import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataTableInitialState } from '../../../interfaces';
import { RootState } from '../../store';

export const dataTableInitialState: DataTableInitialState = {
  demo: false,
  contractSigned: true,
  page: 1,
  perPage: 10,
  sort: 'createdDate:desc',
  verified: false,
};

export const DataTableSlice = createSlice({
  name: 'dataTable',
  initialState: dataTableInitialState,
  reducers: {
    setDataTable: (state, action: PayloadAction<DataTableInitialState>) => {
      state.demo = action.payload.demo;
      state.contractSigned = action.payload.contractSigned;
      state.page = action.payload.page;
      state.perPage = action.payload.perPage;
      state.sort = action.payload.sort;
      state.verified = action.payload.verified;
    },
    setInitialDataTableState: (state) => {
      state.demo = dataTableInitialState.demo;
      state.contractSigned = dataTableInitialState.contractSigned;
      state.page = dataTableInitialState.page;
      state.perPage = dataTableInitialState.perPage;
      state.sort = dataTableInitialState.sort;
      state.verified = dataTableInitialState.verified;
    },
  },
});

export const { setDataTable, setInitialDataTableState } =
  DataTableSlice.actions;

export const selectDataTable = (state: RootState) => state.dataTable;

export default DataTableSlice.reducer;
