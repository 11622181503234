import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '../state/store';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (request) => {
    if (request.url?.includes('/api')) {
      const stores = store.getState();
      request.headers['Authorization'] = `Bearer ${stores.token.value}`;
      if (stores.tenantId.value) {
        request.headers['X-TenantId'] = stores.tenantId.value;
      }
    }
    if (request.url?.includes('/generate-pdf')) {
      request.headers['Content-Type'] = 'text/plain';
    }
    return request;
  },
  (error: AxiosError) => Promise.reject(error.toJSON())
);

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => res.data,
  (error: AxiosError) => Promise.reject(error.toJSON())
);

export default axiosInstance;
