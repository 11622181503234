/*must be synchronized with bootstrap breakpoints*/
import { CompanyIdentifier, FilesProps, Master } from './interfaces';
import countries from 'i18n-iso-countries';
import fr from 'i18n-iso-countries/langs/fr.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const dataIntegrationRequiredFields: { [key: string]: string[] } = {
  tiers: ['ref', 'type', 'addressLine1', 'city', 'zipCode'],
  movements: [
    'tiersRef',
    'documentNumber',
    'type',
    'documentDate',
    'dueDate',
    'debit',
    'credit',
  ],
};

const isPdf = (bytesArray: Uint8Array) => {
  let pdf = false;
  const arrayToCheck = bytesArray.slice(0, 5);
  const pdfSignature1 = [37, 80, 68, 70, 45];
  const pdfSignature2 = [223, 191, 52, 235, 206];
  for (let i = 0; i < 5; i += 1) {
    if (
      arrayToCheck[i] === pdfSignature1[i] ||
      arrayToCheck[i] === pdfSignature2[i]
    ) {
      pdf = true;
    } else {
      pdf = false;
      break;
    }
  }
  return pdf ? 'application/pdf' : 'image/*';
};

const getFileUrlFromArrayBuffer = (
  bytes: ArrayBuffer,
  type?: string
): FilesProps => {
  const bytesArray = new Uint8Array(bytes);
  const blobType = isPdf(bytesArray);
  const blob = new Blob([bytesArray], { type: type || blobType });
  return { blobType: type || blobType, url: URL.createObjectURL(blob) };
};

const isoToName = (iso: string) => {
  countries.registerLocale(fr);
  return countries.getName(iso, 'fr');
};

const renderLines = (lines: number) => {
  let linesArr: any[] = [];
  for (let i = 1; i <= lines; i += 1) {
    linesArr.push(i);
  }
  return linesArr;
};

const isNil = (value: any) => {
  return value === null || value === undefined;
};
const isNilOrEmptyString = (value: any) => {
  return value === '' || value === null || value === undefined;
};

const getSiren = (identifiers: CompanyIdentifier[] | undefined): string => {
  let siren = '';
  identifiers?.forEach((identifier) => {
    if (identifier.type === 'SIREN') {
      siren = identifier.value;
    }
  });
  return siren;
};
const formatAddress = (address: any) => {
  if (!address) return null;
  let formatedAddress = '';
  for (let key in address) {
    if (key !== 'countryIso3') {
      formatedAddress += address[key] ? `${address[key]}, ` : '';
    } else {
      formatedAddress += address[key] ? `${isoToName(address[key])}` : '';
    }
  }
  return formatedAddress;
};

const getFileIdFromUrl = (url: string) => {
  if (!url) return '';
  const urlArr = url.split('/');
  return urlArr.length > 0 ? urlArr[urlArr.length - 1] : url;
};

const getFileLoad = (bytes: ArrayBuffer, fileName: string): any => {
  const bytesArray = new Uint8Array(bytes);
  const blob = new Blob([bytesArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

const formatManager = (manager: any) => {
  if (!manager) return null;
  let formatedManager = `${manager.function} : `;
  for (let key in manager) {
    if (key !== 'function') {
      formatedManager += manager[key] ? `${manager[key]} ` : '';
    }
  }
  return formatedManager;
};

const containsOne = (obj: any | undefined) => {
  return obj && obj.length > 0;
};

const genPwd = (length: number): string => {
  const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_-+={[}]|:;<,>.?/';
  let result = '';
  let values = new Uint32Array(length);
  window.crypto.getRandomValues(values);
  for (let i = 0; i < length; i++) {
    result += charset[values[i] % charset.length];
  }
  if (!regex.test(result)) {
    return genPwd(length);
  } else {
    return result;
  }
};

const getSubjectEmailReminder = (mailContent: string): string => {
  let subject = mailContent.split('SUBJECT:')[1];
  subject = subject.split('BODY:')[0];
  return subject.trim();
};

const getBodyEmailReminder = (mailContent: string): string => {
  const body = mailContent.split('BODY:')[1];
  return body.trim();
};
const objToString = (obj: any): string => {
  let str = '';
  for (let p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str += `${p}:${obj[p]}`;
    }
  }
  return str;
};

const addMasterRefProps = (master: Master | undefined) => {
  if (!master) return null;
  return {
    masterRef: master.reference,
    masterCurrency: master.settings.accountingCurrency,
  };
};

const getEmailFromTiers = (tiers: any | undefined): string => {
  if (tiers?.hasOwnProperty('collectionContact')) {
    return tiers.collectionContact?.email || '';
  }
  if (tiers?.email) {
    return tiers.email || '';
  }

  return '';
};

const getKeyByValue = (object: any, value: string) => {
  const values = Object.values(object).filter((item) => item === value);
  if (values.length !== 1) return void 0;

  return Object.keys(object).find((key) => object[key] === value);
};

const getArraysIntersection = ({
  arr1,
  arr2,
}: {
  arr1: string[];
  arr2: string[];
}): string[] => {
  const setFromArray = new Set(arr2);
  return Array.from(new Set(arr1)).filter((item) => setFromArray.has(item));
};

const getArraysDifference = ({
  arr1,
  arr2,
}: {
  arr1: string[];
  arr2: string[];
}): string[] => {
  const setFromArray = new Set(arr2);
  return Array.from(new Set(arr1)).filter((x) => !setFromArray.has(x));
};

const arraysHasDuplicateEntries = (array: string[]): boolean => {
  const setFromArray = new Set(array);
  return Array.from(setFromArray).length !== array.length;
};

const getDuplicatesFromArray = (array: string[]): string[] => {
  const dupicates: string[] = array.filter(
    (item: string, index: number) => array.indexOf(item) !== index
  );

  return Array.from(new Set(dupicates));
};

const removeKeysWithEmptyValueFromObject = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  return Object.keys(obj)
    .filter((k) => obj[k] !== '')
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

const objectsHaveSameKeys = (...objects: any[]): boolean => {
  const allKeys = objects.reduce(
    (keys, object) => keys.concat(Object.keys(object)),
    []
  );
  const union = new Set(allKeys);
  return objects.every((object) => union.size === Object.keys(object).length);
};

const getClassName = (error: any, touched: any, other: string = '') => {
  return error && touched ? `error ${other}` : other;
};

const utils = {
  addMasterRefProps,
  arraysHasDuplicateEntries,
  breakpoints,
  containsOne,
  dataIntegrationRequiredFields,
  isNil,
  isNilOrEmptyString,
  isoToName,
  isPdf,
  formatAddress,
  formatManager,
  genPwd,
  getArraysDifference,
  getArraysIntersection,
  getBodyEmailReminder,
  getClassName,
  getDuplicatesFromArray,
  getEmailFromTiers,
  getFileIdFromUrl,
  getFileLoad,
  getFileUrlFromArrayBuffer,
  getKeyByValue,
  getSiren,
  getSubjectEmailReminder,
  objectsHaveSameKeys,
  objToString,
  removeKeysWithEmptyValueFromObject,
  renderLines,
};

export const getIcon = (param: boolean) => {
  return param ? (
    <FontAwesomeIcon
      aria-label="icon"
      data-tag="allowRowEvents"
      icon={faCheck}
      style={{ color: '#00a0a6' }}
    />
  ) : (
    <FontAwesomeIcon
      aria-label="icon"
      data-tag="allowRowEvents"
      icon={faXmark}
      style={{ color: 'red' }}
    />
  );
};

/**
 * @param date
 * @returns La date sous forme de String au format "dd/MM/yyyy hh:mm"
 */
export const getDateLocale = (date: Date): string => {
  const dateLocale = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes()
    )
  );
  const day =
    dateLocale.getDate() < 10
      ? '0' + String(dateLocale.getDate())
      : String(dateLocale.getDate());
  const month =
    dateLocale.getMonth() < 10
      ? '0' + String(dateLocale.getMonth() + 1)
      : String(dateLocale.getMonth());
  const minutes =
    dateLocale.getMinutes() < 10
      ? '0' + String(dateLocale.getMinutes())
      : String(dateLocale.getMinutes());
  const hours =
    dateLocale.getHours() < 10
      ? '0' + String(dateLocale.getHours())
      : String(dateLocale.getHours());
  return (
    day +
    '/' +
    month +
    '/' +
    String(dateLocale.getFullYear()) +
    ' ' +
    String(hours) +
    ':' +
    String(minutes)
  );
};

export default utils;
