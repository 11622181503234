import i18n from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { User } from '../interfaces';
import GenericButton from './generic-button';

const SwitchLang = ({ user }: { user: User | undefined }) => {
  const languages: string[] = useMemo(() => ['fr', 'en'], []);
  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const changeLanguage = (lng: string) => {
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => setCurrentLanguage(i18n.language), [currentLanguage]);

  if (!user || user?.dataLocation !== 'dev') return <></>;
  return (
    <>
      <hr />
      <ButtonGroup aria-label="Languages">
        {languages.map((lang, index) => {
          return (
            <GenericButton
              key={index}
              {...(currentLanguage?.includes(lang) ? { active: true } : '')}
              label={`button.${lang}`}
              action={() => changeLanguage(lang)}
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default SwitchLang;
