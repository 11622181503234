import {useParams} from 'react-router-dom';
import {ColBreadcrumbItemProps} from '../shared/interfaces';
import React, {useMemo, useState} from 'react';
import {Col, Row, Tab} from 'react-bootstrap';
import BreadcrumbWrapper from '../shared/components/breadcrumb-wrapper';
import {useGetTenantByIdAndMasterQuery} from '../shared/services/tenantApi';
import CardLinks from '../shared/components/card-links';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import GeneralTab from '../shared/components/master-info/general-tab';
import PaymentTab from '../shared/components/master-info/payment-tab';

const TenantMasterInfoPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<any>({
    general: true,
    payment: false,
    documents: false,
    validation: false,
  });
  const { master, tenantId } =
    useParams<{ master: string; tenantId: string }>();

  const updateActiveTab = (tab: string) => {
    const tabState: any = {
      general: false,
      payment: false,
      documents: false,
      validation: false,
    };
    tabState[tab] = true;
    setActiveTab(tabState);
  };
  const breadCrumbItems: ColBreadcrumbItemProps[] = useMemo(
    () => [
      {
        linkGoTo: '/tenants',
        linkLabel: 'menu.tenants',
        active: false,
      },
      {
        linkGoTo: `/tenants/${tenantId}`,
        linkLabel: `${tenantId}`,
        active: false,
      },
      { linkLabel: `${master}`, active: true },
    ],
    [master, tenantId]
  );

  const { data: tenant, isFetching } = useGetTenantByIdAndMasterQuery({
    masterRef: master,
    tenantId: tenantId,
    qp: new URLSearchParams({}).toString(),
  });

  return (
    <>
      <Row className="row d-flex justify-content-center">
        <Col sm={9}>
          <BreadcrumbWrapper items={breadCrumbItems} />
        </Col>
      </Row>

      <Row className="row d-flex justify-content-center no-gutters card-container">
        <Col sm={2} className="card card-left">
          <Tab.Content>
            <h4 className="card-left-header">
              {isFetching ? <Skeleton width={200} /> : tenant?.name}
            </h4>
            <CardLinks
              label={t('master-info.tab.general')}
              active={activeTab.general}
              onClick={() =>
                !activeTab.general ? updateActiveTab('general') : {}
              }
            />
            <CardLinks
              label={t('master-info.tab.payment')}
              active={activeTab.payment}
              onClick={() =>
                !activeTab.payment ? updateActiveTab('payment') : {}
              }
            />
          </Tab.Content>
        </Col>
        <Col sm={7} className="card card-right card-content">
          {activeTab.general && (
            <GeneralTab isFetching={isFetching} tenant={tenant} />
          )}
          {activeTab.payment && (
            <PaymentTab isFetching={isFetching} tenant={tenant} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TenantMasterInfoPage;
