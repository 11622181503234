import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface VisibilityState {
  value: boolean;
}

const initialState: VisibilityState = {
  value: false,
};

export const VisibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setVisibility } = VisibilitySlice.actions;

export default VisibilitySlice.reducer;
