import { KeycloakInstance } from 'keycloak-js';

export const getBaseUrl = (url: URL) => {
  let baseUrl = `${url.protocol}//${url.hostname}`;
  if (url.port) {
    baseUrl += `:${url.port}`;
  }
  return baseUrl;
};

export const login = (kc: KeycloakInstance) => {
  const url = new URL(window.location.href);
  let baseUrl = getBaseUrl(url);

  kc.login({
    redirectUri: `${baseUrl}/tenants`,
  });
};

export const logout = (kc: KeycloakInstance) => {
  const url = new URL(window.location.href);
  let baseUrl = getBaseUrl(url);

  kc.logout({
    redirectUri: `${baseUrl}/tenants`,
  });
};
