import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const ProtectedRoute = ({ children }: { children: any }) => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({});
  }, [location, trackPageView]);
  if (!keycloak?.authenticated) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
