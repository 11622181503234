import {TenantMasterInfo} from '../../interfaces';
import {Col, Row, Tab} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Utils from '../../utils';

const GeneralTab = ({
  isFetching,
  tenant,
}: {
  isFetching: boolean;
  tenant: TenantMasterInfo | undefined;
}) => {
  const { t } = useTranslation();

  const getAddress = () => {
    return tenant ? Utils.formatAddress(tenant?.address) : null;
  };

  return (
    <Tab.Content>
      <h2 className="tab-title">{t('master-info.title.general')}</h2>
      <Row className="info-row">
        <Col sm={3} className="info-title">
          Adresse mail
        </Col>
        <Col className="info-value">
          {isFetching ? <Skeleton width={200} /> : tenant?.email}
        </Col>
      </Row>
      <Row className="info-row">
        <Col sm={3} className="info-title">
          Numéro de téléphone
        </Col>
        <Col className="info-value">
          {isFetching ? <Skeleton width={100} /> : tenant?.phone}
        </Col>
      </Row>
      <Row className="info-row">
        <Col sm={3} className="info-title">
          Adresse
        </Col>
        <Col className="info-value">
          {isFetching ? <Skeleton width={500} /> : getAddress()}
        </Col>
      </Row>
    </Tab.Content>
  );
};

export default GeneralTab;
