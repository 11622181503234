import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TiersProps} from '../../../interfaces';

export interface TiersState {
  value: TiersProps | null;
}

const initialState: TiersState = {
  value: null,
};

export const TiersSlice = createSlice({
  name: 'tiers',
  initialState,
  reducers: {
    registerTiers: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { registerTiers } = TiersSlice.actions;

export default TiersSlice.reducer;
