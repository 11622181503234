import { AuthClientTokens } from '@react-keycloak/core/lib/types';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React, { useEffect } from 'react';
import './assets/css/App.scss';
import { AppRouter } from './routes';
import Loading from './shared/components/loading';
import './shared/i18n';
import Api from './shared/services/Api';
import { useAppDispatch, useAppSelector } from './shared/state/redux-hooks';
import {
  registerKeycloackConfig,
  selectKeycloackConfig,
} from './shared/state/slices/keycloackConfig/keyCloakConfigSlice';
import { registerKeycloakToken } from './shared/state/slices/token/tokenSlice';

function App() {
  const dispatch = useAppDispatch();
  const keyCloakConfig = useAppSelector(selectKeycloackConfig);

  useEffect(() => {
    if (!keyCloakConfig) {
      Api.getOidcConfiguration().then((conf) =>
        dispatch(registerKeycloackConfig(conf))
      );
    }
  }, [dispatch, keyCloakConfig]);

  if (!keyCloakConfig?.url) {
    return <Loading message="loading.oidc" />;
  }

  const keycloak = Keycloak(keyCloakConfig);

  const tokenLogger = (tokens: AuthClientTokens) => {
    console.log(
      'onTokens',
      tokens.token?.substring(0, 8),
      '...',
      tokens.token?.substring(tokens?.token?.length - 8),
      new Date()
    );
    dispatch(registerKeycloakToken(tokens.token));
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
        pkceMethod: 'S256',
      }}
    >
      <AppRouter />
    </ReactKeycloakProvider>
  );
}

export default App;
