import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { meApi } from '../services/meApi';
import dataTableReducer from './slices/dataTable/dataTableSlice';
import keycloackConfigReducer from './slices/keycloackConfig/keyCloakConfigSlice';
import tenantIdReducer from './slices/tenantId/tenantIdSlice';
import tiersReducer from './slices/tiers/tiersSlice';
import tokenReducer from './slices/token/tokenSlice';
import visibilityReducer from './slices/visibility/visibilitySlice';
import { tenantApi } from '../services/tenantApi';
import { metricsApi } from '../services/metricsApi';
import { contactApi } from '../services/contactApi';
import { templateApi } from '../services/templateApi';
import { reminderApi } from '../services/reminderApi';

export const store = configureStore({
  reducer: {
    dataTable: dataTableReducer,
    token: tokenReducer,
    tenantId: tenantIdReducer,
    tiers: tiersReducer,
    keycloackConfig: keycloackConfigReducer,
    visibility: visibilityReducer,
    [meApi.reducerPath]: meApi.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [reminderApi.reducerPath]: reminderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      meApi.middleware,
      tenantApi.middleware,
      metricsApi.middleware,
      contactApi.middleware,
      templateApi.middleware,
      reminderApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
