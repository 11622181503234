import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryOptions from './baseQueryOptions';
import { Contact } from '../interfaces';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: baseQueryOptions,
  keepUnusedDataFor: 60,
  tagTypes: ['Contact'],
  endpoints: (builder) => ({
    createContact: builder.mutation<
      any,
      {
        contact: Contact;
        tenantId: string;
        qp?: string;
      }
    >({
      query: (queryArgs: { contact: any; tenantId: string; qp?: string }) => ({
        url: `/api/admin/tenants/${queryArgs.tenantId}/contacts?${queryArgs.qp}`,
        method: 'POST',
        body: queryArgs.contact,
      }),
      invalidatesTags: ['Contact'],
    }),
  }),
});

export const { useCreateContactMutation } = contactApi;
