import React, { useMemo } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ColBreadcrumbItemProps, ColBreadcrumbProps } from '../interfaces';
import BreadcrumbItem from './breadcrumb-item';
import I18nWrapper from './i18n-wrapper';

const BreadcrumbWrapper = (props: ColBreadcrumbProps) => {
  const items: ColBreadcrumbItemProps[] = useMemo(
    () => props.items,
    [props.items]
  );

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
        <I18nWrapper translateKey="breadcrumb.home" />
      </Breadcrumb.Item>
      {items.map((item, index) => {
        return <BreadcrumbItem key={index} item={item} />;
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbWrapper;
