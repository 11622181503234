import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import * as KeyCloackService from '../shared/services/keycloack';

function NotAuthenticated() {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    return () => {
      login();
    };
  });

  const login = () => {
    KeyCloackService.login(keycloak);
  };

  return <></>;
}

export default NotAuthenticated;
