import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {KeycloakConfig} from 'keycloak-js';
import {RootState} from '../../store';

export interface KeycloackConfigState {
  value: KeycloakConfig | null;
}

const initialState: KeycloackConfigState = {
  value: null,
};

export const KeyCloakConfigSlice = createSlice({
  name: 'keycloackConfig',
  initialState,
  reducers: {
    registerKeycloackConfig: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { registerKeycloackConfig } = KeyCloakConfigSlice.actions;

export const selectKeycloackConfig = (state: RootState) =>
  state.keycloackConfig.value;

export default KeyCloakConfigSlice.reducer;
